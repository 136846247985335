import { Injectable } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/angular';

type RouteMetadata = {
  title: string;
  isPageHeader: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RouteMetadataService {

  constructor(
    private uiRouterGlobals: UIRouterGlobals
  ) { }

  public getActiveRouteMetadata(): RouteMetadata | undefined {
    const routeName = this.uiRouterGlobals.current.name;

    return this.getRouteMetadata(routeName);
  }

  public getRouteMetadata(routeName: string): RouteMetadata | undefined {
    switch (routeName) {
      // Auth routes
      case 'apps.auth':
      case 'apps.auth.unauthorized':
      case 'apps.auth.signin':
        return {
          title: 'Sign In',
          isPageHeader: false
        };

      case 'apps.auth.createaccount':
      case 'apps.auth.joinaccount':
      case 'apps.auth.signup':
      case 'apps.auth.unregistered':
        return {
          title: 'Sign Up',
          isPageHeader: false
        };

      case 'apps.auth.requestpasswordreset':
      case 'apps.auth.resetpassword':
        return {
          title: 'Reset Password',
          isPageHeader: false
        };

      case 'apps.auth.unsubscribe':
        return {
          title: 'Unsubscribe',
          isPageHeader: false
        };

      case 'apps.auth.confirmaccount':
      case 'apps.auth.provision':
        return {
          title: 'Account Confirmation',
          isPageHeader: false
        };

      // Main app routes
      case 'apps':
      case 'apps.home':
        return {
          title: 'Home',
          isPageHeader: false
        };

      case 'apps.selector':
        return {
          title: 'Selector',
          isPageHeader: false
        };

      case 'apps.displays.alerts':
        return {
          title: 'CAP Alerts',
          isPageHeader: true
        };

      case 'apps.billing':
      case 'apps.billing.home':
        return {
          title: 'Account & Billing',
          isPageHeader: true
        };

      case 'apps.billing.subscription':
        return {
          title: 'Subscription',
          isPageHeader: true
        };

      case 'apps.billing.unpaid':
        return {
          title: 'Unpaid Invoices',
          isPageHeader: true
        };

      case 'apps.billing.invoice':
        return {
          title: 'Invoice',
          isPageHeader: true
        };

      case 'apps.billing.payment':
        return {
          title: 'Add Payment Source',
          isPageHeader: true
        };

      case 'apps.company':
        return {
          title: 'Company',
          isPageHeader: true
        };

      case 'apps.company.add':
        return {
          title: 'Add Sub-Company',
          isPageHeader: true
        };

      case 'apps.company.details':
        return {
          title: 'Company Settings',
          isPageHeader: true
        };

      case 'apps.company.licenses':
        return {
          title: 'Companies & Licenses',
          isPageHeader: true
        };

      case 'apps.displays':
      case 'apps.displays.home':
      case 'apps.displays.list':
        return {
          title: 'Displays',
          isPageHeader: true
        };

      case 'apps.displays.add':
      case 'apps.displays.activate':
      case 'apps.displays.install':
      case 'apps.displays.bulk-activation':
        return {
          title: 'Add Display',
          isPageHeader: true
        };

      case 'apps.displays.change':
      case 'apps.displays.details':
        return {
          title: 'Display Settings',
          isPageHeader: true
        };

      case 'apps.editor':
      case 'apps.editor.home':
      case 'apps.editor.list':
        return {
          title: 'Presentations',
          isPageHeader: true
        };

      case 'apps.editor.add':
        return {
          title: 'Presentations',
          isPageHeader: false
        };

      case 'apps.editor.workspace':
      case 'apps.editor.workspace.artboard':
      case 'apps.editor.workspace.htmleditor':
      case 'apps.editor.templates':
      case 'apps.editor.templates.edit':
        return {
          title: 'Edit Presentation',
          isPageHeader: false
        };

      case 'apps.purchase':
      case 'apps.purchase.plans':
      case 'apps.purchase.home':
        return {
          title: 'Subscribe',
          isPageHeader: false
        };

      case 'apps.purchase.licenses':
      case 'apps.purchase.change-plan':
        return {
          title: 'Change Subscription',
          isPageHeader: true
        };

      case 'apps.purchase.update-plan':
      case 'apps.purchase.licenses.change':
        return {
          title: 'Change Subscription Type',
          isPageHeader: true
        };

      case 'apps.purchase.licenses.add':
        return {
          title: 'Add Display Licenses',
          isPageHeader: true
        };

      case 'apps.purchase.licenses.remove':
        return {
          title: 'Remove Display Licenses',
          isPageHeader: true
        };

      case 'apps.purchase.frequency':
        return {
          title: 'Adjust Billing Frequency',
          isPageHeader: true
        };

      case 'apps.purchase.licenses.unlimited':
        return {
          title: 'Upgrade Subscription',
          isPageHeader: true
        };

      case 'apps.schedules':
      case 'apps.schedules.home':
      case 'apps.schedules.list':
        return {
          title: 'Schedules',
          isPageHeader: true
        };

      case 'apps.schedules.details':
        return {
          title: 'Schedule Settings',
          isPageHeader: true
        };

      case 'apps.schedules.add':
        return {
          title: 'Add Schedule',
          isPageHeader: true
        };

      case 'apps.screen-sharing':
      case 'apps.screen-sharing.home':
      case 'apps.screen-sharing.moderator-join':
      case 'apps.screen-sharing.moderator-room':
        return {
          title: 'Screen Share',
          isPageHeader: true
        };

      case 'apps.storage':
      case 'apps.storage.home':
        return {
          title: 'Storage',
          isPageHeader: true
        };

      case 'apps.user':
      case 'apps.user.list':
        return {
          title: 'Users',
          isPageHeader: true
        };

      case 'apps.user.add':
      case 'apps.user.add2':
        return {
          title: 'Add User',
          isPageHeader: true
        };

      case 'apps.user.details':
        return {
          title: 'User Settings',
          isPageHeader: true
        };

      default:
        return undefined;
    }
  }
}
