import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface MenuAction {
  id: string;
  label: string;
  action: () => void;
  requireRole?: string;
  disabled?: boolean;
}

export interface ActionsMenu {
  saveAction?: MenuAction;
  cancelAction?: MenuAction;
  deleteAction?: MenuAction;
  moreOptions?: MenuAction[];
}

@Component({
  selector: 'common-actions',
  templateUrl: './common-actions.component.html',
})
export class CommonActionsComponent {
  @Input() title: string;
  @Output() titleChange = new EventEmitter<string>();

  @Input() actions: ActionsMenu;
  @Input() saving = false;
  @Input() changeDate?: Date;
  @Input() changedBy?: string;
}
