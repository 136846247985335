<div class="tw-pb-4">
  <div rvSpinner="schedule-loader" [rvShowSpinner]="scheduleService.loadingSchedule">
    <form #scheduleForm="ngForm" id="forms.scheduleForm" role="form" name="forms.scheduleForm"
      (ngSubmit)="save()" autocomplete="on" novalidate>
      <common-actions
        [(title)]="scheduleService.schedule.name"
        [actions]="actions"
        [saving]="scheduleService.savingSchedule"
        [changeDate]="scheduleService.schedule.changeDate"
        [changedBy]="scheduleService.schedule.changedBy">
      </common-actions>

      <schedule-fields></schedule-fields>
    </form>
  </div>
</div>
